<template>
  <div v-if="order">
    <b-card>
      <div slot="header">
        <strong>{{ $t('order #') }}{{ order.orderNumber }}</strong>
      </div>
      <div class="orderContainer">
        <zoo-baza v-if="order.managers.length === 0 || order.managers[0].title === 'ZB'" :order="order"/>
        <zoo-market v-else-if="order.managers.length > 0 && order.managers[0].title === 'ZM'" :order="order" />
        <zoo-olx v-else :order="order" />
      </div>
      <div>
          <b-row>
            <b-col sm="12" md="6" lg="3">
              <label>{{ $t('status') }}</label>
              <div>
                {{ order.statuses.length>0 ? order.statuses[0].title : ''}}
              </div>
            </b-col>
            <b-col sm="12" md="6" lg="3">
              <label>{{ $t('manager') }}</label>
              <div>
                {{ order.managers.length>0 ? order.managers[0].title : ''}}
              </div>
            </b-col>
            <b-col sm="12" md="6" lg="3">
              <label>{{ $t('driver') }}</label>
              <div>
                {{ order.drivers.length>0 ? order.drivers[0].title : ''}}
              </div>
            </b-col>
            <b-col sm="12" md="6" lg="3">
              <label>{{ $t('paymentMethod') }}</label>
              <div>
                {{ order.paymentMethods.length>0 ? order.paymentMethods[0].title : ''}}
              </div>
            </b-col>
          </b-row>
        </div>
    </b-card>
  </div>
</template>

<script>
  import {APIService} from "../../services/api";
  import ZooBaza from "./components/ZooBaza";
  import ZooMarket from "./components/ZooMarket";
  import ZooOlx from "./components/ZooOlx";

  export default {
    name: 'ViewOrderHistory',
    components: {
      ZooMarket,
      ZooBaza,
      ZooOlx,
    },
    props: ['id', 'orderHistoryId'],
    data: () => {
      return {
        order: null,
      }
    },
    async beforeMount() {
      try {
        const order = await APIService.get(`order-history/${this.id}/${this.orderHistoryId}`)
        order.positions = order.positions.filter(position => !position.removed);
        this.order = order;
      } catch (e) {
        console.error(e);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .orderContainer {
    width: 100%;
    overflow: scroll;
    background: #fff;
    padding: 0;
    margin: 0;
  }
</style>
